<template>
  <div>
    <div class="font-weight-bold text-subtitle-1 primary--text">
      <div v-if="isMobile">{{ stringForKey("cardDetails") }}</div>
      <div v-else>{{ stringForKey("creditCard") }}</div>
    </div>
    <div v-if="isMobile">
      <div id="card-number" class="cardElement"></div>
      <div id="card-cvc" class="cardElement"></div>
      <div id="card-expiry" class="cardElement"></div>
    </div>
    <div v-else>
      <div id="card-element" class="cardElement"></div>
    </div>
    <v-alert
      class="font-weight-medium text-caption"
      color="red"
      border="left"
      text
      dense
      v-show="cardError"
      >{{ cardError }}</v-alert
    >
    <div v-if="!canSubmit || loading">
      <v-btn
        color="primary"
        block
        light
        class="white--text font-weight-bold text-subtitle-1 my-4 px-10"
        style="height: 55px"
        @click="createPayment"
        :loading="loading"
        :disabled="true"
      >
        {{ stringForKey("startMembership") }}
      </v-btn>
    </div>
    <div v-else>
      <v-btn
        color="primary"
        block
        light
        class="white--text font-weight-bold text-subtitle-1 my-4 px-10"
        style="height: 55px"
        @click="createPayment"
        :loading="loading"
        :disabled="false"
      >
        {{ stringForKey("startMembership") }}
      </v-btn>
    </div>
    <div id="payment-request-button" class="my-2">
      <!-- A Stripe Element will be inserted here. -->
    </div>
    <div class="secondaryGrey--text mt-5" style="font-size: 10px">
      <div v-if="hasOneOffProduct">
        {{ paymentTermsOneOff }}
      </div>
      <div v-else-if="freeTrial">
        {{ paymentTermsTrial }}
      </div>
      <div v-else>
        {{ paymentTerms }}
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCcAmex } from "@fortawesome/free-brands-svg-icons/faCcAmex";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa";
import { faCcDinersClub } from "@fortawesome/free-brands-svg-icons/faCcDinersClub";
import { faCcJcb } from "@fortawesome/free-brands-svg-icons/faCcJcb";
import subscribe from "../subscribe";
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  mixins: [subscribe],
  components: {
    FontAwesomeIcon,
    StripeElementCard,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      paymentRequest: null,
      card: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardName: null,
      complete: false,
      initLoading: true,
      loading: false,
      cardError: null,
      error: null,
      icons: {
        visa: faCcVisa,
        mastercard: faCcMastercard,
        amex: faCcAmex,
        jcb: faCcJcb,
        dinersClub: faCcDinersClub,
      },
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
    };
  },
  watch: {
    annual(val) {
      if (this.paymentRequest == null) {
        return;
      }
      this.paymentRequest.update({
        total: {
          label: val
            ? `${getStringForKey(
                this.selectedLocale,
                "yearly"
              )} ${getStringForKey(this.selectedLocale, "subscription")}`
            : `${getStringForKey(
                this.selectedLocale,
                "monthly"
              )} ${getStringForKey(this.selectedLocale, "subscription")}`,
          amount: this.price.subunit,
        },
      });
      this.event({
        event: "Toggle Plan",
        properties: {
          plan_billing: val ? "Annually" : "Monthly",
        },
      });
    },
    selectedLocale(val) {
      this.setupStripe();
    },
  },
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
      enforceCurrencyOnDeal: "account/enforceCurrencyOnDeal",
    }),
    stripeElements() {
      return this.$stripe.elements({ locale: this.selectedLocale.key });
    },
    canSubmit() {
      return this.complete;
    },
    stripe() {
      return this.account.stripe;
    },
    paymentTermsTrial() {
      return getStringForKey(this.selectedLocale, "termsPaymentTrial")
        .replace("{{ freeTrial }}", this.freeTrial)
        .replace("{{ amount }}", this.amount);
    },
    paymentTerms() {
      return getStringForKey(this.selectedLocale, "termsPayment").replace(
        "{{ amount }}",
        this.amount
      );
    },
    paymentTermsOneOff() {
      return getStringForKey(this.selectedLocale, "termsPaymentOneOff").replace(
        "{{ amount }}",
        this.amount
      ).replaceAll("{{ period }}", this.period);
    },
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    createPayment() {
      // // console.log((this.stripe);
      // this.handlePaymentThatRequiresCustomerAction(this.stripe);
      // return;

      this.cardError = null;
      this.loading = true;

      var paymentMethod = {
        type: "card",
        card: this.card,
        // billing_details: {
        //   name: this.name,
        // },
      };

      this.$stripe.createPaymentMethod(paymentMethod).then((result) => {
        // console.log((result);
        if (result.error) {
          this.displayError(result);
        } else {
          this.createSubscription({ paymentMethodId: result.paymentMethod.id });
        }
      });
    },

    displayError(event) {
      // debugger
      if (event.error) {
        this.cardError = event.error.message;
      } else {
        this.cardError = null;
      }
      if (event.complete != null) {
        if (this.isMobile) {
          this.complete =
            this.cardNumberComplete &&
            this.cardCvcComplete &&
            this.cardExpiryComplete;
        } else {
          this.complete = event.complete;
        }
      }
      this.loading = false;
    },
    createSubscription({ paymentMethodId, ev }) {
      var payload = {
        paymentMethodId,
        priceId: this.price.price_id,
        productId: this.product.product_id,
        currency: this.currency,
      };

      this.subscribe(payload)
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: payload.paymentMethodId,
            priceId: payload.priceId,
            ...result,
            ev,
          };
        })
        .then(this.handlePaymentThatRequiresCustomerAction)
        .then(this.handleRequiresPaymentMethod)
        .then(this.onSubscriptionComplete)

        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // console.log((error, "error");
          this.displayError(error);
        });
    },

    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
      ev,
    }) {
      //check setupintent
      let pendingSetupIntent = subscription?.pending_setup_intent;
      if (pendingSetupIntent) {
        //check it
        const { client_secret, status } = subscription.pending_setup_intent;
        if (status === "requires_action") {
          return this.$stripe
            .confirmCardSetup(client_secret)
            .then(function (result) {
              if (result.error) {
                // Display error.message in your UI.
              } else {
                // The setup has succeeded. Display a success message.
                if (ev) {
                  ev.complete("success");
                }
                return { subscription, priceId, paymentMethodId };
              }
            });
        }
        return;
      }

      if (
        subscription &&
        (subscription.status === "active" || subscription.status === "trialing")
      ) {
        // Subscription is active, no customer actions required.
        if (ev) {
          ev.complete("success");
        }
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice
        ? invoice.payment_intent
        : subscription?.latest_invoice.payment_intent;

      // console.log((paymentIntent, "payment intent");
      if (
        paymentIntent.status === "requires_action" ||
        (isRetry === true && paymentIntent.status === "requires_payment_method")
      ) {
        return this.$stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            // console.log((result);
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              if (ev) {
                ev.complete("fail");
              }
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // Show a success message to your customer.
                if (ev) {
                  ev.complete("success");
                }
                return {
                  priceId: priceId,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                  paymentSuccess: true,
                };
              }
            }
          });
      } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId };
      }
    },

    handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
      paymentSuccess,
    }) {
      if (subscription) {
        if (
          subscription.status === "active" ||
          subscription.status === "trialing"
        ) {
          // subscription is active, no customer actions required.
          return { subscription, priceId, paymentMethodId, paymentSuccess };
        } else if (
          subscription.latest_invoice.payment_intent &&
          subscription.latest_invoice.payment_intent.status ===
            "requires_payment_method"
        ) {
          throw { error: { message: "Your card was declined." } };
        }
      }
      return { subscription, priceId, paymentMethodId, paymentSuccess };
    },

    onSubscriptionComplete(result) {
      // Payment was successful.
      var success = false;
      if (
        result.subscription &&
        (result.subscription.status === "active" ||
          result.subscription.status === "trialing")
      ) {
        success = true;
      }
      if (result.paymentSuccess) {
        success = true;
      }
      if (success) {
        this.$router.push("/download");
      }

      this.loading = false;
    },

    async init() {
      try {
        const response = await fetch(
          `https://ipapi.co/json/?key=${process.env.VUE_APP_IP_API_KEY}`
        );
        const location = await response.json();

        var country;
        if (location && location.currency) {
          const forcedCurrency = this.enforceCurrencyOnDeal;
          if (forcedCurrency != null) {
            this.setCurrency(forcedCurrency);
          } else {
            const c = location.currency;
            if (c == "USD" || c == "GBP" || c == "EUR") {
              this.setCurrency(c);
            }
          }
          country = location.country;
        }

        this.setupStripe(country);
        this.initLoading = false;
      } catch (e) {
        // console.log(e);
        this.initLoading = false;
      }
    },
    setupStripe(country) {
      const style = {
        base: {
          color: "black",
          fontWeight: 500,
          fontFamily: "Helvetica Neue",
          fontSize: "15px",
          lineHeight: "40px",
          "::placeholder": {
            color: "#706882",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };

      if (this.isMobile) {
        this.card = this.stripeElements.create("cardNumber", {
          style: style,
        });
        this.cardExpiry = this.stripeElements.create("cardExpiry", {
          style: style,
        });
        this.cardCvc = this.stripeElements.create("cardCvc", {
          style: style,
        });
        this.card.mount("#card-number");
        this.cardExpiry.mount("#card-expiry");
        this.cardCvc.mount("#card-cvc");

        let self = this;
        this.card.on("change", function (event) {
          // debugger;
          self.cardNumberComplete = event.complete;
          self.displayError(event);
        });
        this.cardExpiry.on("change", function (event) {
          self.cardExpiryComplete = event.complete;
          self.displayError(event);
        });
        this.cardCvc.on("change", function (event) {
          self.cardCvcComplete = event.complete;
          self.displayError(event);
        });
      } else {
        this.card = this.stripeElements.create("card", {
          style: style,
        });
        this.card.mount("#card-element");

        let self = this;
        this.card.on("change", function (event) {
          // debugger;
          self.displayError(event);
        });
      }

      if (country) {
        this.paymentRequest = this.$stripe.paymentRequest({
          country: country,
          currency: this.currency.toLowerCase(),
          total: {
            label: `${getStringForKey(
              this.selectedLocale,
              "monthly"
            )} ${getStringForKey(this.selectedLocale, "subscription")}`,
            amount: this.price.subunit,
          },
          // requestPayerName: true,
          // requestPayerEmail: true,
        });

        var prButton = this.stripeElements.create("paymentRequestButton", {
          paymentRequest: this.paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        this.paymentRequest.canMakePayment().then(function (result) {
          if (result) {
            prButton.mount("#payment-request-button");
          } else {
            document.getElementById("payment-request-button").style.display =
              "none";
          }
        });

        let _this = this;
        this.paymentRequest.on("paymentmethod", function (ev) {
          _this.cardError = null;
          _this.loading = true;

          _this.createSubscription({
            paymentMethodId: ev.paymentMethod.id,
            ev,
          });
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeMount() {
    this.initLoading = true;
  },

  beforeDestroy() {
    if (this.card) {
      this.card.destroy();
    }
    if (this.cardExpiry) {
      this.cardExpiry.destroy();
    }
    if (this.cardCvc) {
      this.cardCvc.destroy();
    }
  },
};
</script>
