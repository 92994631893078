import { mapState, mapActions, mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
      deal: "account/deal",
      products: "account/products",
      annual: "subscribe/annual",
      currency: "subscribe/currency",
      selectedLocale: "account/getSelectedLocale",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    product() {
      if (this.hasOneOffProduct) {
        return this.products[0];
      }
      return this.annual ? this.products[0] : this.products[1];
    },
    hasAnnualProduct() {
      if (this.hasOneOffProduct) {
        return;
      }
      return (
        this.products[0].prices.find((p) => p?.currency === this.currency) !=
        null
      );
    },
    hasMonthlyProduct() {
      if (this.hasOneOffProduct) {
        return null;
      }
      return (
        this.products[1].prices.find((p) => p?.currency === this.currency) !=
        null
      );
    },
    hasOneOffProduct() {
      return this.products.find((p) => p.term === "single") != null;
    },
    amount() {
      if (this.price == null) {
        return "";
      }

      const price = this.price.price;
      let discount = price * (this.discountPercent / 100);
      let newPrice = price - discount;
      let amount = `${this.price.symbol}${newPrice.toFixed(2)}`;
      if (!this.hasOneOffProduct) {
        amount = `${amount}/${this.product.term}`;
      }
      return amount;
    },

    period() {
      console.log(this.product.interval_count);
      console.log(this.product);

      const count = this.product.interval_count;

      if (this.product.interval === "year") {
        return count * 12;
      }

      return count;
    },

    defaultAmount() {
      const price = this.price.price;
      return `${this.price.symbol}${price.toFixed(2)}/${this.product.term}`;
    },
    discountPercent() {
      if (this.deal) {
        let subscription = this.deal.subscription;
        if (subscription && subscription.discount_percent) {
          return subscription.discount_percent;
        }
      }
      return 0;
    },
    yearlyDiscountPercent() {
      let totalYearly = this.yearlyPrice.price;
      let totalMonth = this.monthlyPrice.price * 12;

      return (((totalMonth - totalYearly) * 100) / totalMonth).toFixed(0);
    },
    price() {
      return this.product.prices.find((p) => p.currency === this.currency);
    },
    monthlyPrice() {
      return this.products[1].prices.find((p) => p.currency === this.currency);
    },
    yearlyPrice() {
      return this.products[0].prices.find((p) => p.currency === this.currency);
    },
    firstName() {
      if (this.account.register) {
        if (this.account.register.name && this.account.register.name !== "") {
          let chunks = this.account.register.name.split(/\s+/);
          if (chunks[0]) {
            return chunks[0];
          }
        }
      }
      return "there";
    },
    pageTitle() {
      if (this.hasOneOffProduct) {
        return `${getStringForKey(this.selectedLocale, "hi")} ${
          this.firstName
        }`;
      }

      if (this.isMobile) {
        return `${getStringForKey(this.selectedLocale, "hi")} ${
          this.firstName
        }, ${getStringForKey(this.selectedLocale, "choosePlan")}`;
      }
      return `${getStringForKey(this.selectedLocale, "hi")} ${
        this.firstName
      }, ${getStringForKey(this.selectedLocale, "heresOffer")}`;
    },
    monthlySubscriptiontitle() {
      return getStringForKey(this.selectedLocale, "withUSubscription");
    },
    annualSubscriptionTitle() {
      return getStringForKey(this.selectedLocale, "withUSubscription");
    },
    oneOffPriceInterval() {
      const product = this.product;
      let period = "";
      switch (product.interval) {
        case "day":
          period = getStringForKey(this.selectedLocale, "days");
          break;
        case "week":
          period = getStringForKey(this.selectedLocale, "weeks");
          break;
        case "month":
          period = getStringForKey(this.selectedLocale, "monthsPlural");
          break;
        case "year":
          period = getStringForKey(this.selectedLocale, "years");
          break;
      }

      return `${product.interval_count} ${period}`;
    },
  },
  methods: {
    ...mapActions({
      subscribe: "account/subscribe",
      event: "account/events",
      setAnnual: "subscribe/setAnnual",
      setCurrency: "subscribe/setCurrency",
    }),
    upgrade() {
      this.setAnnual(!this.annual);
    },
  },
  beforeMount() {
    this.setAnnual(this.hasMonthlyProduct === false);
  },
  mounted() {
    this.$gtm.trackEvent({
      event: "Step2Reg",
    });
  },
};
